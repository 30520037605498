export const PAGE_NAME = "responsive kaysta";
export const PAGE_AUTHOR = "responsive IT";
export const PAGE_DESCRIPTION = "finest full stack development";
export const PAGE_TOPIC = "finest reactive mobile web application";
export const PAGE_KEYWORDS = "reactjs, css3, html5, responsive";

export const BG_IMAGE =
  "https://cdn.responsive-it.biz/assets/bg-images/mainboard-1174219.jpg";

export const ICON_FILE = "https://cdn.responsive-it.biz/assets/icons/icon.png";

export const APP_VERSION = "0.9.2";

export const API_PARCEL =
  "80Muze3fQb9acFuJDzgOehjTx6DyRb0iCfiOWufHIZK7udecUndiRu8iq2Eevq3pZAPufYoUnmPf6ZTNZ9wPsTzeA/Btf41pNLLxGvmOlb57eAn5XeB552Zuslo4zBzlzB8DzZLwNNRsLa3/7+ICclj3HDxmHRimG9XgphjA+TRBtWOK64YBlK0o2O6+v/RwqdhfW0+NGWLiIrZxasiGQnVUbkbzYgFURS/P4GX0MCf4EyRn9YK34L6S8/GL3TXn";

// --------------------------------------------------------------------------------------
// responsive-kaysta.ch
// --------------------------------------------------------------------------------------

export const GOOGLE_KEY = "G-7HLQK1PL3X";
export const ANALYTICS_COOKIE_KEY = "responsive_kaysta_cookie";
export const PAGE_ORIGIN = "responsive-kaysta.ch";
export const PUBLIC_URL = `https://${PAGE_ORIGIN}`;
// export const API_URL = `https://api.master-archive.news/`;
export const API_URL = `https://test-api-master-archive.azurewebsites.net`;
